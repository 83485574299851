import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Icon1 from '../images/icon1.png'
import Icon2 from '../images/icon2.png'
import Icon3 from '../images/icon3.png'
import Icon4 from '../images/icon4.png'
import { motion } from 'framer-motion'
import Aos from 'aos'
import 'aos/dist/aos.css'

const useStyles = makeStyles((theme) => ({
  backgroundWhatWeDo: {
    height: 'auto'
  },
  titleWhatWeDo: {
    textAlign: 'center',
    paddingTop: '100px',
    fontSize: 42,
    color: '#2F2F30',
    [theme.breakpoints.down('lg')]: {
      fontSize: 42
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: '90px',
      fontSize: 30
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: '80px',
      fontSize: 27
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: '60px',
      fontSize: 28
    }
  },
  subTitleWhatWeDo: {
    textAlign: 'center',
    fontSize: 15,
    color: '#2F2F30',
    [theme.breakpoints.up('xl')]: {
      padding: '30px 420px 30px 420px',
    },
    [theme.breakpoints.down('lg')]: {
      padding: '30px 350px 10px 350px',
    },
    [theme.breakpoints.down('md')]: {
      padding: '30px 210px 30px 200px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '30px 40px',
      fontSize: 17
    },
    [theme.breakpoints.down('xs')]: {
      padding: '40px',
      fontSize: 14
    }
  },
  iconsWhatWeDo: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-evenly',
    flexWrap: 'wrap',
    [theme.breakpoints.up('xl')]: {
      padding: '0 410px 150px 410px'
    },
    [theme.breakpoints.down('lg')]: {
      padding: '0 350px 110px 350px'
    },
    [theme.breakpoints.down('md')]: {
      padding: '0 150px 100px 150px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '10px 100px 35px 100px'
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0'
    }
  },
  iconImg: {
    textAlign: 'center',
    [theme.breakpoints.up('lg')]: {
      width: '10%'
    },
    [theme.breakpoints.up('md')]: {
      width: '15%'
    },
    [theme.breakpoints.down('md')]: {
      width: '20%'
    },
    [theme.breakpoints.down('sm')]: {
      width: '34%'
    },
    [theme.breakpoints.down('xs')]: {
      width: '51%',
      paddingBottom: '50px',
      paddingTop: '15px'
    }
  },
  titleIcon: {
    fontSize: 15,
    paddingTop: '15px'
  },
  subTitleIcon: {
    fontSize: 12,
    paddingTop: '15px',
    color: '#2F2F30'
  }
}));

function WhatWeDo() {
  const classes = useStyles()

  useEffect(() => {
    Aos.init({ duration: 1500 })
  }, [])
  
  return (
    <div className={classes.backgroundWhatWeDo} id='whatwedo'>
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 3.0 }}>
        <Typography className={classes.titleWhatWeDo}>O QUE NÓS FAZEMOS</Typography>
        <Typography className={classes.subTitleWhatWeDo}>Nós criamos produtos sob medida e soluções para todos os tipo de plataformas e dispositivos. Android, iOS, utilizando tecnologia nativa, que é mais rápida e fluida, e aplicações Web, que funcionam em qualquer dispositivo. A nossa engenharia de classe empresarial oferece a melhor experiência possível, garantindo que o produto ou solução funcione perfeitamente em qualquer dispositivo e em qualquer lugar ao redor do globo.</Typography>
      </motion.div>
      <div data-aos="fade-up" className={classes.iconsWhatWeDo}>
        <div className={classes.iconImg}>
          <img src={Icon3} alt='roadmapps' />
          <Typography className={classes.titleIcon}>Aplicativos</Typography>
          <Typography className={classes.subTitleIcon}>Android ou iOS, ambos com tecnologia nativa.</Typography>
        </div>
        <div className={classes.iconImg}>
          <img src={Icon2} alt='roadmapps' />
          <Typography className={classes.titleIcon}>Web</Typography>
          <Typography className={classes.subTitleIcon}>Sites e sistemas web responsivos, rápidos, já instalados na nuvem.</Typography>
        </div>
        <div className={classes.iconImg}>
          <img src={Icon1} alt='roadmapps' />
          <Typography className={classes.titleIcon}>Integrações</Typography>
          <Typography className={classes.subTitleIcon}>Robôs de integração para conectar seus sistemas existentes.</Typography>
        </div>
        <div className={classes.iconImg}>
          <img src={Icon4} alt='roadmapps' />
          <Typography className={classes.titleIcon}>Ux/Ui</Typography>
          <Typography className={classes.subTitleIcon}>Projetos de interface/experiência impactantes para o seu negócio.</Typography>
        </div>
      </div>
    </div>
  )
}

export default WhatWeDo