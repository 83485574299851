import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import bgHome from '../images/fundo1.png'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { Link } from 'react-scroll'
import EmptyCel from '../images/borda_cel.png'
import InsideCel from '../images/tela_cel.png'
import Carousel from '@brainhubeu/react-carousel'
import '@brainhubeu/react-carousel/lib/style.css'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import { motion } from 'framer-motion'

const theme = createTheme({
  typography: {
    fontFamily: 'BrandonGrotesque'
  },
  overrides: {
    MuiButton: {
      contained: {
        '&:hover': {
          backgroundColor: '#d00f4f'
        }
      }
    }
  }
})

const useStyles = makeStyles((theme) => ({
  backgroundOne: {
    padding: '125px 100px 100px 100px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    backgroundImage: `url('${bgHome}')`,
    backgroundPositionX: 'right',
    width: 'auto',
    maxHeight: '350px',
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#E7E7E7',
    [theme.breakpoints.down('md')]: {
      backgroundSize: '75%',
    },
    [theme.breakpoints.down('sm')]: {
      backgroundSize: '67%',
      flexDirection: 'column',
      padding: '90px',
      maxHeight: '550px'
    },
    [theme.breakpoints.down('xs')]: {
      backgroundSize: '64%',
      padding: '25px'
    }
  },
  solutions: {
    "&::before": {
      left: 0,
      content: `''`,
      position: 'absolute',
      backgroundColor: '#d52e5f',
      height: '250px',
      width: '60px',
      transform: 'skew(-20deg)',
      [theme.breakpoints.up('xl')]: {
        marginLeft: '175px',
      },
      [theme.breakpoints.down('xl')]: {
        marginLeft: '175px',
      },
      [theme.breakpoints.down('lg')]: {
        marginLeft: '110px'
      },
      [theme.breakpoints.down('md')]: {
        marginTop: '10px',
        marginLeft: '70px',
        width: '65px',
        height: '230px'
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: '40px',
        marginLeft: '40px',
        width: '45px',
        height: '150px'
      },
      [theme.breakpoints.down('xs')]: {
        width: '24px',
        height: '110px',
        marginTop: '85px',
        marginLeft: '20px'
      }
    },
    maxWidth: '50%',
    [theme.breakpoints.up('xl')]: {
      marginLeft: '85px',
      marginTop: '10px'
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      textAlign: 'center'
    }
  },
  cellphone: {
    maxWidth: '20%',
    [theme.breakpoints.up('xl')]: {
      marginRight: '250px',
    },
    [theme.breakpoints.up('lg')]: {
      marginRight: '50px',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  titleHome: {
    fontSize: 34,
    color: '#555455',
    lineHeight: 1.1,
    padding: '30px 100px 15px 60px',
    [theme.breakpoints.down('md')]: {
      padding: '15px',
      fontSize: 32
    },
    [theme.breakpoints.down('sm')]: {
      padding: '65px 30px 30px 30px',
      fontSize: 24,
      textAlign: 'left'
    },
    [theme.breakpoints.down('xs')]: {
      padding: '40px 20px',
      fontSize: 20,
      marginTop: '70px',
      marginLeft: '22px',
      lineHeight: '25px'
    }
  },
  subTitleHome: {
    fontSize: 17,
    color: '#555455',
    [theme.breakpoints.up('lg')]: {
      padding: '0px 120px 0px 60px'
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: '13px',
      textAlign: 'left'
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '40px',
      padding: '0px',
      fontSize: 15,
      lineHeight: '25px'
    }
  },
  cellphoneImg: {
    height: '540px',
    filter: 'drop-shadow(0px 0px 3px black)',
    display: 'flex',
    zIndex: 3
  },
  insideCellPhoneImg: {
    display: 'flex',
    position: 'absolute',
    width: '245px',
    height: '515px',

  },
  buttonHome: {
    fontSize: 16,
    backgroundColor: '#d52e5f',
    color: '#FFFFFF',
    padding: '10px 50px',
    marginTop: '50px',
    [theme.breakpoints.up('xl')]: {
      marginRight: '320px',
    },
    [theme.breakpoints.up('lg')]: {
      marginRight: '220px',
    },
    [theme.breakpoints.down('lg')]: {
      marginTop: '60px',
      marginRight: '10px'
    },
    [theme.breakpoints.down('xs')]: {
      padding: '8px 40px',
      marginRight: '0px',
      marginTop: '30px',
      fontSize: 14
    }
  },
  phoneDiv: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('lg')]: {
      marginTop: '-25px',
      marginLeft: '20px'
    },
    [theme.breakpoints.up('md')]: {
      marginTop: '-25px',
      marginLeft: '20px'
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: '150px',
      paddingRight: '0px'
    }
  },
  carrosselApps: {
    width: '240px',
    position: 'absolute',
    marginTop: '5px'
  },
  carrosselImgs: {
    height: '550px',
    marginTop: '-10px',
    marginLeft: '1px',
    borderRadius: '50px'
  }
}))

function Home({portfolio_projects = []}) {
  const classes = useStyles()

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.backgroundOne} id='home'>
        <div className={classes.solutions}>
          <motion.div initial={{ y: '70vw', opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ type: 'spring', delay: 0.3, stiffness: 60 }}>
            <Typography className={classes.titleHome}>Soluções criativas, eficazes e com tecnologia de ponta</Typography>
            <Typography className={classes.subTitleHome}>Somos uma empresa criativa e tecnológica especializada em aplicações mistas (mobile e web). Nossa experiência em usabilidade, design & tecnologia ajuda nossos clientes na construção de um relacionamento interpessoal mais forte com o público através da tecnologia.</Typography>
          </motion.div>
          <motion.div initial={{ x: '-70vw', opacity: 0 }} animate={{ x: 0, opacity: 1 }} transition={{ type: 'spring', delay: 0.3, stiffness: 120 }} style={{ textAlign: 'center' }}>
            <Button className={classes.buttonHome} href='#contact' variant="contained"><Link activeClass="active" to="contact" spy={true} smooth={true} offset={-70} duration={1000} href="#contact" color='inherit'>CONTATO</Link></Button>
          </motion.div>
        </div>
        <motion.div initial={{ y: '70vw', opacity: 0 }} animate={{ y: 0, delay: 0.3, opacity: 1 }} transition={{ type: 'spring', delay: 0.3, stiffness: 60 }} className={classes.cellphone}>
          <div className={classes.phoneDiv}>
            <img src={EmptyCel} alt='cellphone' className={classes.cellphoneImg} />
            <img src={InsideCel} alt='cellphone' className={classes.insideCellPhoneImg} />
            <div className={classes.carrosselApps}>
              <Carousel className={classes.carrosselImgs}
                autoPlay={5000}
                animationSpeed={1500}
                infinite
              >
                {portfolio_projects.map((portfolio_project) => (
                  // <img key={portfolio_project.name} src={portfolio_project.image} style={{ width: '100%' }} />
                  <div key={portfolio_project.name} style={{ width: '100%', height: 515, backgroundImage: `url(${portfolio_project.image})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}></div>
                ))}
              </Carousel>
            </div>
          </div>
        </motion.div>
      </div>
    </ThemeProvider>
  )
}

export default Home