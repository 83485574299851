import React from 'react';
import ReactDOM from 'react-dom/client';
import Public from '../public/Public.jsx';

const root_div = document.getElementById('root')
const root = ReactDOM.createRoot(root_div);
const props = JSON.parse(root_div.dataset['parameters']);
root.render(
  <React.StrictMode>
    <Public flash={props.flash} />
  </React.StrictMode>
);
