import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    marginBottom: 10,
    height: '100vh',
    backgroundColor: '#E7E7E7',
    fontFamily: 'BrandonGrotesque',
  },
  paper: {
    padding: theme.spacing(2),
    height: 150,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    fontSize: '3rem',
    backgroundColor: '#C53F60',
    color: '#FFF',
  },
  paper2: {
    padding: theme.spacing(2),
    display: 'flex',
    textAlign: 'left',
    flexDirection: 'column',
    marginLeft: '30px',
    fontSize: '20px',
    backgroundColor: '#E7E7E7',
  },
  title: {
    fontSize: '2.5rem',
    marginTop: 0,
    marginBottom: 0,
  },
  text: {
    fontSize: '1.875rem',
  },
  principal: {
    fontSize: '4rem',
  },
  general: {
    display: 'flex',
  },
}));

export default function CenteredGrid() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid className={classes.general} container spacing={3}>
        <Grid item xs={12} style={{ padding: 0, backgroundColor: '#E7E7E7' }}>
          <Paper elevation={0} className={classes.paper}>
            <h1 className={classes.principal}>TERMOS DE USO</h1>
          </Paper>
        </Grid>
        <Grid item xs={12} style={{ padding: 0, backgroundColor: '#E7E7E7' }}>
          <Paper elevation={0} className={classes.paper2}>
            <h2 className={classes.title}>OBJETIVO</h2>
            <p className={classes.text}>
              Aplicativo construído pela Prefeitura do Recife com o intuito de
              melhorar o gerenciamento das informações de testagem dos exames de
              Covid-19.
            </p>
            <h2 className={classes.title}>QUEM PODE USAR O APLICATIVO?</h2>
            <p className={classes.text}>
              O aplicativo é de uso exclusivo da equipe de gestão e enfermeiros
              responsáveis pela testagem de exames de Covid. Qualquer uso que
              não seja realizado por estes usuários é estritamente proibido.
            </p>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
