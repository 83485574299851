import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import MenuItem from '@material-ui/core/MenuItem'
import MenuIcon from '@material-ui/icons/Menu'
import { useScrollTrigger } from '@material-ui/core'
import Slide from '@material-ui/core/Slide'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuList from '@material-ui/core/MenuList'
import MarcaWeb from '../images/marca_web.png'
import MarcaWeb2 from '../images/marca_v3.png'
import { Link } from 'react-scroll'
import './Header.css'

function HideOnScroll(props) {
  const window = props
  const trigger = useScrollTrigger({ disableHysteresis: false, threshold: 0, target: props.window ? window() : undefined })
  return (
    <div>
      <Slide appear={false} direction="down" in={!trigger && !props.open}>
        {React.cloneElement(props.children, { style: { marginTop: '-5px', position: 'absolute', boxShadow: 'none', backgroundColor: 'transparent' } })}
      </Slide>
      <Slide appear={true} direction="down" in={trigger || props.open}>
        {React.cloneElement(props.children, { style: { marginTop: '-5px', boxShadow: 'none', backgroundColor: trigger || props.open ? '#d52e5f' : 'transparent' } })}
      </Slide>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  marcaImg: {
    width: '200px',
    [theme.breakpoints.up('xl')]: {
      marginLeft: '170px',
      marginTop: '20px'
    },
    [theme.breakpoints.down('lg')]: {
      marginLeft: '20px'
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: '18px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '170px'
    },
    [theme.breakpoints.down('xs')]: {
      width: '135px',
      marginTop: '20px'
    }
  },
  menuComp: {
    flexGrow: 1,
    color: '#FFFFFF',
    fontSize: 17,
    padding: '0px 0px 0px 25px',
    fontWeight: '500',
    [theme.breakpoints.up('lg')]: {
      fontSize: 20,
      padding: '0px 23px'
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  menuIcon: {
    fontSize: 40,
    color: '#FFFFFF',
    marginBottom: '-15px'
  },
  toolBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between'
    }
  },
  menuLg: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    [theme.breakpoints.up('lg')]: {
      marginRight: '60px',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  menuSm: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block'
    }
  },
  menuList: {
    width: '100%',
    backgroundColor: '#d52e5f',
    borderRadius: '0px',
    color: '#FFFFFF',
  },
  menuItemDrop: {
    padding: '8px 30px',
    fontSize: 13,
    fontWeight: '500',
    margin: '0 auto',
  },
  popper:{
    top: 56,
    left: 0,
    right: 0,
    bottom: 0,
    transform: 'translate3d(0px, 54px, 0px) !important'
  }
}))

const Header = props => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)
  const [active, setActive] = React.useState(false)

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault()
      setOpen(false)
    }
  }

  const prevOpen = React.useRef(open)
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }
    prevOpen.current = open
  }, [open])

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll)
  }, [])

  function handleScroll() {
    const { pageYOffset } = window
    if (pageYOffset >= 1) {
      setActive(true)
    } else {
      setActive(false)
    }
  }
  
  return (
    <div>
      <HideOnScroll {...props} open={open} >
        <AppBar style={{ backgroundColor: 'transparent', boxShadow: 'none'}}>
          <Toolbar className={classes.toolBar}>
            <div>
              {(active || open) && <img src={MarcaWeb2} alt='roadmapps' className={classes.marcaImg} />}
              {!active && !open && <img src={MarcaWeb} alt='roadmapps' className={classes.marcaImg} />}
            </div>
            <div className={classes.menuLg}>
              <Typography className={classes.menuComp}><Link activeClass="active" to="home" spy={true} smooth={true} offset={-70} duration={1000} href='#home' color='inherit'>Bem-vindo</Link></Typography>
              <Typography className={classes.menuComp}><Link activeClass="active" to="whatwedo" spy={true} smooth={true} offset={-70} duration={1000} href='#whatwedo' color='inherit'>O que fazemos</Link></Typography>
              <Typography className={classes.menuComp}><Link activeClass="active" to="clients" spy={true} smooth={true} offset={-70} duration={1000} href='#clients' color='inherit'>Clientes</Link></Typography>
              <Typography className={classes.menuComp}><Link activeClass="active" to="team" spy={true} smooth={true} offset={-70} duration={1000} href='#team' color='inherit'>Time</Link></Typography>
              <Typography className={classes.menuComp}><Link activeClass="active" to="whereweare" spy={true} smooth={true} offset={-70} duration={1000} href='#whereweare' color='inherit'>Onde estamos</Link></Typography>
              <Typography className={classes.menuComp}><Link activeClass="active" to="contact" spy={true} smooth={true} offset={-70} duration={1000} href="#contact" color='inherit'>Contato</Link></Typography>
              <Typography className={classes.menuComp}><a href="/blog" color='inherit'>Blog</a></Typography>
            </div>
            <div className={classes.menuSm}>
              <Button ref={anchorRef} aria-controls={open ? "menu-list-grow" : undefined} aria-haspopup="true" onClick={handleToggle}>
                <MenuIcon className={classes.menuIcon} />
              </Button>
              <Popper className={classes.popper} style={{}} open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === "bottom" ? "center top" : "center bottom"
                    }}
                  >
                    <Paper className={classes.menuList}>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                          <MenuItem onClick={handleClose}><Link activeClass="activemobile" to="home" spy={true} smooth={true} offset={-70} duration={1000} href='#home' className={classes.menuItemDrop} color='inherit'>Bem-vindo</Link></MenuItem>
                          <MenuItem onClick={handleClose}><Link activeClass="activemobile" to="whatwedo" spy={true} smooth={true} offset={-70} duration={1000} href='#whatwedo' className={classes.menuItemDrop} color='inherit'>O que fazemos</Link></MenuItem>
                          <MenuItem onClick={handleClose}><Link activeClass="activemobile" to="clients" spy={true} smooth={true} offset={-70} duration={1000} href='#clients' className={classes.menuItemDrop} color='inherit'>Clientes</Link></MenuItem>
                          <MenuItem onClick={handleClose}><Link activeClass="activemobile" to="team" spy={true} smooth={true} offset={-70} duration={1000} href='#team' className={classes.menuItemDrop} color='inherit'>Time</Link></MenuItem>
                          <MenuItem onClick={handleClose}><Link activeClass="activemobile" to="whereweare" spy={true} smooth={true} offset={-70} duration={1000} href='#whereweare' className={classes.menuItemDrop} color='inherit'>Onde estamos</Link></MenuItem>
                          <MenuItem onClick={handleClose}><Link activeClass="activemobile" to="contact" spy={true} smooth={true} offset={-70} duration={1000} href="#contact" className={classes.menuItemDrop} color='inherit'>Contato</Link></MenuItem>
                          <MenuItem><a href="/blog" className={classes.menuItemDrop} color='inherit'>Blog</a></MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </div>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
    </div>
  )
}

export default Header